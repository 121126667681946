.pagelayout {
  height: 100%;
  width: 100%;
  padding-top: 84px;
  padding-bottom: 2rem;
  .pagecontent {
    width: 100%;
    max-width: 480px;
    padding: 0 1rem;
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #1a1e26;
    }
    p {
      font-size: 13px;
      line-height: 1.7;
      margin-bottom: 2.1rem;
      color: #252e42;
      b {
        color: #1a1e26;
      }
    }
    .resend {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-size: 13px;
      font-weight: 500;
      color: #007bff;
      cursor: pointer;
    }
  }
  .gmail {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
    font-size: 13px;
    font-weight: 400;
    color: #252e42;
    cursor: pointer;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .logout {
    font-size: 13px;
    font-weight: 500;
    color: #1a1e26;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
