.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  // .customDates {
  //   display: flex;
  //   align-items: center;
  //   .date {
  //     background-color: #ffffff;
  //     height: 30px;
  //     font-size: 1.2rem;
  //     padding: 0 1rem;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border: 1px solid #d2d2d2;
  //     &:not(:last-child) {
  //       border-right: none;
  //     }
  //     &:last-child {
  //       border-top-right-radius: 4px;
  //       border-bottom-right-radius: 4px;
  //     }
  //     &:first-child {
  //       border-top-left-radius: 4px;
  //       border-bottom-left-radius: 4px;
  //     }
  //     &:hover {
  //       cursor: pointer;
  //       background-color: #f5f5f5;
  //     }
  //     &:active {
  //       background-color: #d2d2d2;
  //     }
  //     &.active {
  //       background-color: #99b4ff;
  //     }
  //   }
  // }
}

.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  gap: 26px;
  padding: 0 24px 60px;
  .topGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .statItem {
      padding: 3rem 2rem;
      // change borders to inset box shadows
      &:nth-child(1) {
        border-right: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
      }
      &:nth-child(2) {
        border-right: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
      }
      &:nth-child(3) {
        border-bottom: 1px solid #d2d2d2;
      }
      &:nth-child(4) {
        border-right: 1px solid #d2d2d2;
        border-bottom: 1px solid #d2d2d2;
      }
      &:nth-child(5) {
        border-bottom: 1px solid #d2d2d2;
      }
      &:nth-child(6) {
        border-bottom: 1px solid #d2d2d2;
        border-left: 1px solid #d2d2d2;
        margin-left: -1px;
      }
    }
    .stat {
      display: flex;
      align-items: center;
      gap: 18px;
    }
    .icon {
      height: 54px;
      width: 54px;
      border-radius: 14px;
      background-color: #cdfdcc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .statTitle {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a1e26;
    }
    .statValue {
      font-size: 3.2rem;
      font-weight: 600;
      color: #1a1e26;
    }
  }

  .chartTitle {
    font-size: 18px;
    font-weight: 500;
    color: #1a1e26;
    margin-bottom: 16px;
  }
}

.cardTitle {
  font-size: 14px;
  font-weight: 500;
  color: #1a1e26;
  margin-bottom: 0.5rem;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        &:last-child {
          text-align: right;
          width: 10%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #1a1e26;
        text-align: left;
        width: 13%;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:first-child {
          width: 25%;
          font-weight: 600;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        color: #1a1e26;
        align-items: center;
        padding-right: 20px;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
    }
  }
}

.dateToggler,
.productsToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  span {
    max-width: 240px; /* Set max width */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
  }
}

.productsFilter,
.datesFilter {
  position: relative;
}

.productsListContainer {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 10%);
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  z-index: 100;
  overflow: hidden;
  margin-top: 8px;
  width: 400px; /* Updated width */

  .productsFilterList {
    max-height: 300px;
    overflow-y: auto;

    .productsFilterItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;

        img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }

        .productName {
          font-size: 14px;
          font-weight: 500;
          color: #1a1e26;
          max-width: 200px; /* Set max width */
          white-space: nowrap; /* Prevent wrapping */
          overflow: hidden; /* Hide overflowing text */
          text-overflow: ellipsis; /* Add ellipsis for overflow */
        }
      }

      .productPrice {
        font-size: 14px;
        font-weight: 400;
        color: #6f6f6f;
        margin-left: auto; /* Ensures price aligns to the right */
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
}

.datePicker {
  position: absolute;
  right: 0;
  top: 40px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 2%);
  border: 1px solid #d2d2d2;
  overflow: hidden;
  z-index: 200;
}

.noData {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  text-align: center;
  padding: 50px 0;
}

.country {
  font-size: 15px;
  font-weight: 500;
  color: #1a1e26;
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    font-size: 18px;
  }
}

.loadingState,
.emptyState,
.emptySearchState {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  padding: 20px 0;
}

.form {
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
  .label {
    font-size: 13px;
    font-weight: 500;
    color: #6f6f6f;
    padding: 6px 0;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.clearIcon {
  cursor: pointer;
  color: #6f6f6f;
  &:hover {
    color: #1a1e26;
  }
}

.gridTitle {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #1a1e26;
}

.topContainer {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.productsFilter {
  position: relative;
}

.productsToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  span {
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.productsListContainer {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  z-index: 100;
  margin-top: 8px;
  width: 400px;
  overflow: hidden;
  .productsFilterList {
    max-height: 300px;
    overflow-y: auto;
    .productsFilterItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;
      transition: background-color 0.2s;
      &:hover {
        background-color: #f5f5f5;
      }
      .productNameContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }
        .productName {
          font-size: 14px;
          font-weight: 500;
          color: #1a1e26;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .productPrice {
        font-size: 14px;
        font-weight: 400;
        color: #6f6f6f;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
}

.subscriptionsFilter {
  position: relative;
}

.subscriptionsToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  span {
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.subscriptionsListContainer {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  z-index: 100;
  margin-top: 8px;
  width: 400px;
  overflow: hidden;
  .form {
    padding: 10px;
    .label {
      font-size: 12px;
      color: #6f6f6f;
      margin-bottom: 4px;
    }
  }
  .subscriptionsFilterList {
    max-height: 300px;
    overflow-y: auto;
    .subscriptionsFilterItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;
      transition: background-color 0.2s;
      &:hover {
        background-color: #f5f5f5;
      }
      .subscriptionNameContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;
        img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }
        .subscriptionName {
          font-size: 14px;
          font-weight: 500;
          color: #1a1e26;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .subscriptionPrice {
        font-size: 14px;
        font-weight: 400;
        color: #6f6f6f;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
}
