.bigPageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.customerTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 600;
  color: #1a1e26;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.country {
  font-size: 15px;
  font-weight: 500;
  color: #1a1e26;
  display: flex;
  align-items: center;
  gap: 10px;
  .emoji {
    font-size: 24px;
  }
}

.pageContainer {
  display: grid;
  gap: 4rem;
  .topGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    .stat {
      display: flex;
      align-items: center;
      gap: 18px;
    }
    .icon {
      height: 54px;
      width: 54px;
      border-radius: 14px;
      background-color: #cdfdcc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .statTitle {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a1e26;
    }
    .statValue {
      font-size: 3.2rem;
      font-weight: 600;
      color: #1a1e26;
    }
  }
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 0;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        white-space: nowrap;
        &:last-child {
          text-align: right;
          width: 10%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #1a1e26;
        text-align: left;
        width: 13%;
        cursor: pointer;
        &:last-child {
          text-align: right;
          width: 5%;
        }
        &:first-child {
          width: 9%;
          font-weight: 600;
          padding-right: 30px;
        }
        &:nth-child(2) {
          width: 35%;
          font-weight: 600;
          padding-right: 20px;
        }
        &:nth-child(3) {
          width: 15%;
          font-weight: 600;
          padding-right: 30px;
        }

        &:hover {
          &:first-child {
            color: #3447ef;
          }
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
    }
  }
}

.cardTitle {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.5rem;
}

.reviewsTable {
  tbody {
    tr {
      td {
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:first-child {
          width: 16%;
          font-weight: 600;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        font-weight: 600;
      }
      .review {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviewExpanded {
        overflow: visible;
      }

      .seeMoreButton {
        background: none;
        border: none;
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.ratingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ratingNumber {
    font-size: 14px;
    margin-left: 10px;
    margin-top: -3px;
  }
}

.orderId {
  display: flex;
  align-items: center;
  gap: 10px;
}

.refunded {
  color: #1a1e26;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #e9e8f1;
}
