@import "../../Developers.module.scss";

.cardTitle {
  margin-bottom: 16px;
  font-size: 19px;
  font-weight: 600;
  color: #1a1e26;
}

.emptyState {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #6f6f6f;
  }
}

.webhookUrl {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.itemsList {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 10px 10px;
          font-size: 13px;
          font-weight: 400;
          color: #6f6f6f;
          text-align: left;
          &:last-child {
            text-align: right;
            width: 10%;
          }
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        td {
          padding: 12px 10px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          text-align: left;
          &:last-child {
            text-align: right;
            width: 10%;
          }
          &:not(:first-child) {
            &,
            * {
              white-space: nowrap;
            }
          }
        }
        &:hover {
          background-color: #f5f5f5;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }
  }
}

.status {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #f5f5f5;
  color: #6f6f6f;
}

.active {
  color: #006908;
  background-color: #d7f7c2;
}
