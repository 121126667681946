.navbar {
  // background-color: #ffffff;
  border-bottom: 1px solid #d2d2d2;
  // margin-left: -1rem;
  // width: calc(100% + 2rem);
}

.navBarContainer {
  min-height: 70px;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  @media (max-width: 768px) {
    justify-content: space-between;
    gap: 20px;
  }
}

.navBrand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.creatorProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.creatorName {
  font-size: 18px;
}

.navCta {
  display: flex;
  align-items: center;
  gap: 20px;
  .sep {
    width: 1px;
    height: 20px;
    background-color: #c4c4c4;
  }

  .follow {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.socialLinks {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
}
