.loader {
  /* height: 5rem;
  width: 5rem; */

  /* display: none; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* left: calc(50%-2.5rem);
  top: calc(50%-2.5rem); */

  font-size: 24px;

  /* animation: spin 2s infinite linear; */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
