.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  .customDates {
    display: flex;
    align-items: center;
    .date {
      background-color: #ffffff;
      height: 30px;
      font-size: 1.2rem;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d2d2d2;
      &:not(:last-child) {
        border-right: none;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
      &:active {
        background-color: #d2d2d2;
      }
      &.active {
        background-color: #99b4ff;
      }
    }
  }
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 20px;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        white-space: nowrap;
        &:last-child {
          padding: 20px 0;
          text-align: right;
        }
        &:first-child {
          padding: 20px 0;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &,
        * {
          white-space: nowrap;
        }
        &:last-child {
          padding: 14px 0;
          text-align: right;
          width: 5%;
        }
        &:first-child {
          padding: 14px 0;
          width: 9%;
          font-weight: 600;
          padding-right: 30px;
          &:hover {
            color: #3447ef;
          }
        }
        &:nth-child(2) {
          width: 15%;
          font-weight: 600;
          padding-right: 20px;
        }
        &:nth-child(3) {
          width: 15%;
          font-weight: 600;
          padding-right: 30px;
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          cursor: pointer;
          color: #3447ef;
        }
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }
}

.dateToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}

.datePicker {
  position: absolute;
  right: 0;
  top: 40px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 2%);
  border: 1px solid #d2d2d2;
  overflow: hidden;
  z-index: 200;
}

.customerNameContainer {
  &:hover {
    cursor: pointer;
    color: #3447ef;
  }
  img {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
  }
}

.orderId {
  display: flex;
  align-items: center;
  gap: 10px;
}

.refunded {
  color: #1a1e26;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #e9e8f1;
}

.pageTabsContainer {
  display: flex;
  align-items: center;
  max-width: 300px;
  margin-bottom: 24px;

  .tab {
    flex: 1;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 2px solid #d9d9d9;
    color: #1a1e26;
    font-weight: 500;

    &.active {
      border-bottom: 2px solid #3447ef;
      color: #3447ef;
      font-weight: 600;
    }

    &.disableTab {
      pointer-events: none;
    }
  }
}

.status {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #f5f5f5;
  color: #6f6f6f;
  &.active {
    color: #006908;
    background-color: #d7f7c2;
  }
}

.searchInputContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dateToggler,
.productsToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  span {
    max-width: 240px; /* Set max width */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
  }
}

.productsFilter,
.datesFilter {
  position: relative;
}

.productsListContainer {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 10%);
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  z-index: 100;
  overflow: hidden;
  margin-top: 8px;
  width: 400px; /* Updated width */

  .productsFilterList {
    max-height: 300px;
    overflow-y: auto;

    .productsFilterItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;

        img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }

        .productName {
          font-size: 14px;
          font-weight: 500;
          color: #1a1e26;
          max-width: 200px; /* Set max width */
          white-space: nowrap; /* Prevent wrapping */
          overflow: hidden; /* Hide overflowing text */
          text-overflow: ellipsis; /* Add ellipsis for overflow */
        }
      }

      .productPrice {
        font-size: 14px;
        font-weight: 400;
        color: #6f6f6f;
        margin-left: auto; /* Ensures price aligns to the right */
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
}

.loadingState,
.emptyState,
.emptySearchState {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  padding: 20px 0;
}

.form {
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
  .label {
    font-size: 13px;
    font-weight: 500;
    color: #6f6f6f;
    padding: 6px 0;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.clearIcon {
  cursor: pointer;
  color: #6f6f6f;
  &:hover {
    color: #1a1e26;
  }
}
