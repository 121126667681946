.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.content {
  padding: 20px 20px 40px;
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;
}

.innerTitle {
  font-size: 21px;
  margin-bottom: 2rem;
  font-weight: 500;
}

.tutorialsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  video {
    cursor: pointer;
    flex-grow: 1;
    max-width: 430px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }
  .tutorialTextContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
    .title {
      font-size: 17px;
      font-weight: 500;
      color: #1a1e26;
    }
    .watch {
      display: flex;
      align-items: center;
      gap: 3px;
      color: #3447ef;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: #4f5df1;
      }
    }
  }
}
