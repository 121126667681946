// ImageUpload.module.scss
.imageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadInput {
  display: none;
}

.labelContainer {
  position: relative;
  .uploadLabel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
  }
}

.imagesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  margin-top: 20px;
}

.uploadedImage {
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.removeIcon {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #fff;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
