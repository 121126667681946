.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        white-space: nowrap;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(5) {
          width: 10%;
          padding-right: 30px;
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &:last-child {
          text-align: right;
          width: 5%;
        }
        &:first-child {
          width: 9%;
          font-weight: 600;
          padding-right: 30px;
        }
        &:nth-child(2) {
          width: 19%;
          font-weight: 600;
          padding-right: 20px;
        }
        &:nth-child(3) {
          width: 15%;
          font-weight: 600;
          padding-right: 30px;
        }
        &:nth-child(5) {
          width: 10%;
          padding-right: 30px;
          text-align: center;
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
    }
  }
}


.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  &, * {
    white-space: nowrap;
  }
}



.productsList {
  width: 600px;
}

.productItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .productPrice {
    font-size: 1.4rem;
    font-weight: 500;
    color: #000000;
    margin-left: auto;
    margin-right: 30px;
  }

  .copyButton {
    margin-left: auto;
  }
}

.productNameContainer {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }
  
  .productName {
    font-size: 1.4rem;
    font-weight: 500;
    // ellipsis with 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 250px;
  }
}
