.modalContent {
  width: 880px;
  max-width: 100%;
  a {
    color: #3447ef;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .infoSection {
    display: flex;
    gap: 1rem;
  }
}

.morTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  h2 {
    margin: 0;
  }
  .publishedItem {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #cfd7df;
    color: #1a1e26;
    height: 30.75px;
    padding: 0 0.825rem;
    padding-right: 1.025rem;
    > * {
      transform: scale(0.8);
      margin-right: -4px;
      label {
        background: #b5b5b5;
      }
    }
    span {
      font-size: 15px;
      font-weight: 500;
      font-weight: 500;
    }
    &.published {
      border: 1px solid #56b2a3;
      background: #56b2a313;
      > * {
        label {
          background: #56b2a3;
        }
      }
    }
  }
}
