.pagelayout {
  height: 100%;
  width: 100%;
  padding-top: 84px;
  padding-bottom: 2rem;
  text-align: center;
  .title {
    color: #1a1e26;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
  }

  .iconContainer {
    font-size: 52px;
  }

  .description {
    font-size: 13px;
    line-height: 1.7;
    margin-bottom: 2.1rem;
    font-weight: 500;
    color: #252e42;
    b {
      color: #1a1e26;
    }
  }

  img {
    width: 20px;
    border-radius: 2px;
    border: 1px solid #eeeeee;
  }
}
