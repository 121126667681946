.datePickerContainer {
  position: relative;
  width: 100%;
}

.datePicker {
  appearance: none;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.17);
  color: #1a1e26;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  width: 100%;
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  display: flex;
  &:focus {
    outline: none;
    border-color: #3447ef;
    box-shadow: 0 0 0 1px #3447ef;
  }

  &.isError {
    border-color: #e54f4f;
    box-shadow: 0 0 0 1px #e54f4f;
  }
}

.error {
  color: #e54f4f;
  font-size: 12px;
  margin-top: 4px;
}
