.pageContainer {
  background: #ffffff;
  &:not(.isOnSettings) {
    // height: 100vh;
  }
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .content {
    max-width: 1100px;
    margin: 0 auto;
    flex: 1;
    width: 100%;
    > h1:first-child {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      margin-top: 24px;
      font-weight: 600;
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #d2d2d2;
    }

    h1 {
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 4rem;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 24px;

      @media only screen and (max-width: 912px) {
        font-size: 15px;
      }
    }
    hr {
      margin: 4rem 0;
    }
    iframe,
    img {
      width: 100%;
      height: auto;
      min-height: 400px;
    }
    ul,
    ol {
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      margin-left: 0;

      li {
        font-size: 16px;
        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.footer {
  height: 58px;
  width: 100%;
  padding-bottom: 8px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 6rem;

  font-size: 14px;
  a {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}

.body {
  padding: 0 24px;
}

.navBrand {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  top: 24px;
  left: 24px;
}

.creatorProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.creatorName {
  font-size: 18px;
}
