.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 1rem;
  border-bottom: 1px solid #d9d9d9;
  padding: 18px 0;
  * > {
    flex-wrap: nowrap;
  }
}

.pageTitle {
  color: #1a1e26;
  font-size: 28px;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
}
