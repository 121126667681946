.formGroup {
  display: grid;
  flex-direction: column;
  gap: 6px;
  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .label {
    color: #1a1e26;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    // disable text selection
    user-select: none;
    &.small {
      font-size: 12px;
      margin-bottom: -3px;
    }
  }
}

.error,
.required {
  color: #e74c3c;
  font-size: 1.2rem;
}

.inline {
  display: grid;
  grid-template-columns: auto 1fr;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  grid-template-areas: "input label";
  .labelContainer {
    grid-area: label;
  }
  > *:nth-child(2) {
    grid-area: input;
  }

  &.reversed {
    gap: 1rem;
    .labelContainer {
      grid-area: unset;
    }
    > *:nth-child(2) {
      grid-area: unset;
    }
  }
}

.dummy {
  position: absolute;
}
