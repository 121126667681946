.productPage {
  margin-top: -34px;

  left: 0;
  right: 0;

  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  .bigModalPreviewContainer {
    position: absolute;
    z-index: 999;
    padding: 30px;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.5);
    overflow: auto;

    .pagesBigPreview {
      max-width: 1048px;
      margin: 0 auto;
      button,
      a,
      .paypal-button {
        pointer-events: none;
      }
    }
  }

  .closeContainer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 3px 4px 0px rgb(83 83 83 / 15%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 101;
    cursor: pointer;
    &:hover {
      background: #f2f3f7;
    }
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    max-height: 100px;

    .titleContainer {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding-right: 20px;
    }

    .productTitle {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      // max 2 lines and then ...
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    }

    .isEqualRedDot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #e74c3c;
      flex-shrink: 0;
    }

    .backButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      cursor: pointer;
      flex-shrink: 0;
      &:hover {
        background: rgba(#1a1e26, 0.1);
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      .divider {
        border: none;
        border-left: 1px solid rgba(#1a1e26, 0.2);
        margin: 0 8px;
        height: 24px;
      }
      button {
        height: 30.75px;
        padding: 0.6375rem 1.125rem;
      }
      .publishedItem {
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #cfd7df;
        color: #1a1e26;
        height: 30.75px;
        padding: 0 0.825rem;
        padding-right: 1.025rem;
        > * {
          transform: scale(0.8);
          margin-right: -4px;
          label {
            background: #b5b5b5;
          }
        }
        span {
          font-size: 15px;
          font-weight: 500;
        }
        &.published {
          border: 1px solid #56b2a3;
          background: #56b2a313;
          > * {
            label {
              background: #56b2a3;
            }
          }
        }
      }
    }
  }

  .formContainer {
    border-top: 1px solid #d9d9d9;
    display: flex;
    height: 100%;
    width: 100%;

    .steps {
      background: #edeff6;
      flex: 0.5;
      max-width: 460px;
      border-right: 1px solid #d9d9d9;
      padding: 30px 0;

      height: calc(100vh - 68px);
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .stepsContainer {
      padding: 0 30px;
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      gap: 5rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #333;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
      }

      .stepItem {
        display: flex;
        align-items: center;
        gap: 1rem;
        background: white;
        padding: 12px 22px;
        border-radius: 6px;
        border: 1px solid #e8e8e8;
        z-index: 1;
        cursor: pointer;

        &:hover {
          border: 1px solid #3447ef;
        }

        .iconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 38px;
          border-radius: 30px;
          background: #f3f3f3;
          flex-shrink: 0;
        }

        &.stepItemActive {
          // do inner shadow border as well
          box-shadow: inset 0 0 0 1px #3447ef;
          border: 1px solid #3447ef;
          .iconContainer {
            background: #3447ef;
            color: white;
          }
        }

        .textContainer {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        h5,
        p {
          margin: 0;
          user-select: none;
        }

        h5 {
          font-size: 13px;
          font-weight: 600;
        }

        p {
          font-size: 12px;
          line-height: 17px;
          color: #666;
        }
      }
    }

    .form {
      flex: 1;
      margin: 0 auto;
      background: rgb(248 248 252);
      height: 100%;
      overflow-y: auto;
      max-height: 100%;

      .insideForm {
        // height: calc(100vh - 60px);
        padding: 30px 46px;
        padding-bottom: 170px;
        max-width: 800px;
        margin: 0 auto;
      }
    }
  }

  .step {
    margin: 0 4px;
    h1 {
      color: #1a1e26;
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .desc {
      color: #797b7e;
      font-size: 13px;
      font-family: Inter;
      line-height: 19px;
      margin-bottom: 30px;
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .formGrid {
      align-items: flex-start;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
  }
}

.bottomActions {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}

.savePopup {
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background: #fff;
  border-radius: 4px;
  height: 48px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0px 3px 10px -3px rgb(218 220 231);
  border: 1px solid #d2d2d2;
  .savePopupText {
    font-size: 12px;
    font-weight: 500;
    color: #1a1e26;

    // max 2 lines and then ...
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    word-break: break-word;
  }
  button {
    height: 27px;
    padding: 0.3375rem 1.125rem;
  }
}

.errorToast {
  display: grid;
  gap: 12px;
  margin-left: 8px;
  .errorToastTitle {
    font-size: 14px;
    font-weight: 500;
    color: #1a1e26;
  }
  ul {
    font-size: 13px;
    font-weight: 400;
    color: #797b7e;
    display: grid;
    gap: 8px;
    margin-bottom: 6px;
    margin-top: 0;
    padding-left: 18px;
  }
}

.bankModalText {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #1a1e26;
  max-width: 540px;
  margin: 0 auto;
  background: #ffeeee;
  border: 1px solid #d79c9c;
  padding: 10px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;

  .bankModalIcon {
    color: #eb5858 !important;
    display: block;
    flex-shrink: 0;
  }
}

.sideButtonContainer {
  margin: 0 auto;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  > button {
    padding: 1rem 2rem;
    font-size: 14px;
  }
}

.variantsGrid {
  display: grid;
  gap: 12px;

  .variant {
    display: flex;
    align-items: center;
    gap: 14px;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 4px;
      object-fit: cover;
    }
    .actions {
      margin-left: auto;
      display: flex;
      gap: 8px;
      & > * {
        cursor: pointer;
      }
    }
    .variantContent {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .variantTitle {
        color: #1a1e26;
        font-size: 12px;
        font-weight: 500;
      }
      .variantPrice {
        color: #797b7e;
        font-size: 12px;
      }
    }
  }
}

.deleteModalText {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
}

.selectAll {
  display: flex;
  label {
    font-size: 13px;
    color: #1a1e26;
    font-weight: 500;
  }
}

.idContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  .idIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid #1a1e26;
  }
  .idText {
    font-size: 12px;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace !important;
    color: #1a1e26;
    text-decoration: underline;
    cursor: pointer;
  }
}

.idContainerBig {
  position: relative;
  .copiedId {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #797b7e;
  }
}

.usernameHolder {
  position: relative;
  .suffix {
    position: absolute;
    display: flex;
    align-items: center;
    top: 24px;
    height: 34px;
    right: 1px;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    color: #51565d;
    border-left: 1px solid #e5e5e5;
    padding: 0 12px 0 6px;
    background-color: #f6f8fa;
    max-width: 330px;
    overflow-x: scroll;
    white-space: pre;
  }

  .loading,
  .succeeded,
  .failed {
    position: absolute;
    right: 1px;
    bottom: -16px;

    font-size: 10px;
    color: #51565d;
  }

  .succeeded {
    font-weight: 600;
    color: #2ecc71;
  }

  .failed {
    font-weight: 600;
    color: #e74c3c;
  }
}

.portalUrlContainer {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  width: 100%;
  & > div:first-child {
    flex: 1;
  }
  .copyIcon {
    height: 36px;
    width: 36px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #0000000a;
    }
  }
}

.howToSection {
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
    max-width: 400px;
    margin-bottom: 8px;
  }
  p {
    color: #1a1e26;
    font-size: 13px;
    margin: 0;
    line-height: 1.5;
    code {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace !important;
      background: #f3f3f3;
      display: inline;
      height: 18px;
      padding: 2px 3px;
      border-radius: 4px;
      color: #ea6868;
    }
  }
  li {
    margin: 0;
    padding: 0;
    &:not(&:last-child) {
      margin-bottom: 6px;
    }
  }
}
