.ToolbarContainer {
  background: #000000;
  border-radius: 9px 9px 0 0;
  display: flex;
  align-items: center;
  min-height: 40px;
  // position: sticky;
  top: 0;
  z-index: 1;
  padding: 6px 10px;

  &.sticky {
    border-top: 2px solid transparent;
    box-shadow: 0px 3px 5px -3px rgba(#333333, 0.5);
  }
}

.Toolbar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .icon {
    border-radius: 5px;
    width: 26px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    &.text {
      font-size: 10px;
      white-space: nowrap;
      line-height: 1.4;
      width: auto;
      padding: 0 10px;
      font-weight: 600;
    }

    &.disabled {
      color: rgba(#ffffff, 0.5);
      pointer-events: none;
    }
  }

  .text {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 2px;
  }

  .icon:hover {
    background: #3d3d3d;
    color: #5fccff;
    cursor: pointer;
  }

  .divider {
    border: none;
    border-left: 1px solid rgba(#fff, 0.4);
    margin: 2px 8px;
  }

  .icon + .icon {
    margin-left: 4px;
  }

  .rightItems {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
