.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  .customDates {
    display: flex;
    align-items: center;
    .date {
      background-color: #ffffff;
      height: 30px;
      font-size: 1.2rem;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d2d2d2;
      &:not(:last-child) {
        border-right: none;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
      &:active {
        background-color: #d2d2d2;
      }
      &.active {
        background-color: #99b4ff;
      }
    }
  }
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 10px;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        white-space: nowrap;
        &:last-child {
          padding: 20px 0;
        }
        &:first-child {
          padding: 20px 0;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 10px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &:last-child {
          padding: 14px 0;
          text-align: right;
          width: 5%;
        }
        &:first-child {
          padding: 14px 0;
          width: 9%;
          font-weight: 600;
          padding-right: 30px;
          &:hover {
            color: #3447ef;
          }
        }
        &:nth-child(2) {
          width: 35%;
          font-weight: 600;
          padding-right: 20px;
        }
        &:nth-child(3) {
          width: 15%;
          font-weight: 600;
          padding-right: 30px;
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
          color: #3447ef;
        }
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 4px;
        }
      }
    }
  }
}
