.salesBanner {
  display: flex;
  margin: 1.6rem 0;
  align-items: center;
  border-radius: 4px;
  padding: 1rem;
  .salesText {
    font-size: 1.4rem;
    color: #000000;
    margin-left: .6rem;
  }
  
  &.inBottomBar {
    margin: 0;
    padding: .5rem 1rem;
  }
}