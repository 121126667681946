.page1 {
  min-height: calc(121vh - 58px);
  .userContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 15px;
    margin-bottom: 20px;
    .userImage {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
    .user {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    position: sticky;
    top: 0;
    z-index: 3;
    h1 {
      font-weight: 600;
      font-size: 32px;
      // max 2 lines and then ...
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    button {
      flex-shrink: 0;
    }
    @media only screen and (max-width: 912px) {
      padding: 16px 20px;
      h1 {
        font-size: 28px;
      }
      button {
        display: none;
      }
    }
  }

  .content {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 26px;
    @media only screen and (max-width: 912px) {
      padding: 20px;
    }
  }

  .imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border: 1px solid #dfdfdf;

    .arrowLeft,
    .arrowRight {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: all 0.3s;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 50%;
      padding: 8px;
      opacity: 0;
      pointer-events: none;
      @media only screen and (max-width: 912px) {
        opacity: 1;
      }
    }

    &:hover .arrowLeft,
    &:hover .arrowRight {
      opacity: 1;
      pointer-events: auto;
    }

    .arrowLeft {
      left: 10px;
    }

    .arrowLeft:hover {
      transform: translateX(-3px);
    }

    .arrowRight {
      right: 10px;
    }

    .arrowRight:hover {
      transform: translateX(3px);
    }

    .image {
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  .contentBody {
    display: grid;
    grid-template-columns: 1fr 290px;
    gap: 40px;
    padding: 32px 0;
    a {
      text-decoration: underline;
    }

    @media only screen and (max-width: 912px) {
      grid-template-columns: unset;
      gap: 50px;
      padding-top: 50px;
    }

    .left {
      h1 {
        font-size: 3.2rem;
        font-weight: 500;
        margin-bottom: 4rem;
      }
      p {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 24px;

        @media only screen and (max-width: 912px) {
          font-size: 15px;
        }
      }
      hr {
        margin: 4rem 0;
      }
      iframe,
      img {
        width: 100%;
        height: auto;
        min-height: 400px;
      }
      ul,
      ol {
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        margin-left: 0;

        li {
          font-size: 16px;
          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
      }
      a:not(.added-button) {
        color: #007bff;
        text-decoration: underline;
      }
      a.added-button {
        background-color: #3447ef !important;
        color: #fff;
        border-radius: 4px;
        padding: 10px 14px;
        margin: 0 4px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;

        &:hover {
          background-color: #5469d4;
        }
      }
    }
    .right {
      button {
        width: 100%;
      }
    }
  }
}

.mainCta {
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  &.inBottomBar {
    height: 40px;
    font-size: 15px;
    padding: 10px 20px;
  }
}

.floatingBottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-top: 1px solid #dfdfdf;
  padding: 10px 20px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  transform: translateY(0);
  opacity: 1;

  visibility: hidden;

  .bottomPayWant {
    max-width: 220px;
  }

  @media only screen and (max-width: 912px) {
    visibility: visible;
  }
}

.floatingBottomBarHidden {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.payWantCta {
  display: flex;
  align-items: end;
  gap: 1rem;
  input {
    height: 52px !important;
  }
  * {
    margin-bottom: 0 !important;
  }

  margin-top: -12px;

  @media only screen and (max-width: 912px) {
    display: none;
  }
}
