.variantModal {
  width: 700px !important;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
