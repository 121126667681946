.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        &:nth-child(2) {
          width: 20%;
        }
        &:last-child {
          text-align: right;
          width: 10%;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:first-child {
          width: 35%;
          font-weight: 600;
          padding-right: 30px;
          &:hover {
            * {
              color: #3447ef !important;
            }
          }
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 4px;
          object-fit: cover;
        }
        .productName {
          // max 2 lines and then ...
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          word-break: break-word;
        }
      }
    }
  }
}

.seachInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  > * {
    max-width: 400px;
    width: 100%;
  }
}

.searchInputContainer {
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 14px;
  img {
    width: 24px;
    height: 24px;
    margin-left: -6px;
  }
}

.modalTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 36px;
    height: 36px;
  }
}

.modalContent {
  width: 480px;
  max-width: 100%;

  .redAlert,
  .alert {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
    border-left: 4px solid #3447ef;
    background-color: #e7e8f7;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 1.5;
  }

  .redAlert {
    border-left: 4px solid #ef3434;
    background-color: #f7e7e7;
  }
}

.switch {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lms {
  scale: 0.8;
}

.deleteModalText {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 500;
  color: #000000;
  max-width: 460px;
}

.bankModalText {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #1a1e26;
  max-width: 540px;
  margin: 0 auto;
  background: #ffeeee;
  border: 1px solid #d79c9c;
  padding: 10px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;

  .bankModalIcon {
    color: #eb5858 !important;
    display: block;
    flex-shrink: 0;
  }
}

.learnMore {
  background: #007bff16;
  border: 1px solid #007bff4c;
  color: #1a1e26;
  padding: 8px;
  height: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: -1px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0 12px;
  height: 46px;
  a {
    color: #007bff;
    text-decoration: underline;
  }
}
