.checkInput {
  .checkbox {
    position: absolute;
    opacity: 0;

    // Text
    & + label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 18px;
      padding-left: 18px + 10;
      color: #666; // Label colour

      // Checkbox
      &:before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background: transparent;
        width: 18px;
        height: 18px;

        box-shadow: inset 0 0 0 1px #a2a5ac;
        border-radius: 4px;
        transition: 200ms ease-in-out all;
      }

      // Tick
      &:after {
        position: absolute;
        display: block;
        top: 3px;
        left: 6px;
        content: "";
        width: 4px;
        height: 8px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        transform: rotate(45deg);

        transition: 200ms ease-in-out all;
      }
    }

    // Hover
    & + label:hover {
      color: #333;
    }

    // Focus
    & + label:focus {
      outline: none;
    }

    // Checked
    &:checked {
      & + label {
        &:before {
          background: #3447ef;
          box-shadow: none;
        }
        &:after {
          border-color: white;
        }
      }
    }

    // Disabled
    &:disabled {
      & + label {
        &:before {
          background: #f2f2f2; // Disabled colour
          box-shadow: none;
        }
        &:after {
          border-color: transparent;
        }
      }
    }

    // Disabled Checked
    &:checked:disabled {
      & + label {
        &:before {
          background: #f2f2f2;
          box-shadow: none;
        }
        &:after {
          border-color: #cecece;
        }
      }
    }
  }
}
