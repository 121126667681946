.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.onHold {
  font-size: 13px;
  font-weight: 500;
  background-color: #e9e8f1;
  padding: 6px 16px;
  border-radius: 40px;
  color: #1a1e26;
  margin-left: 8px;
}

.divider {
  border: none;
  border-left: 1px solid rgba(#1a1e26, 0.2);
  margin: 0 8px;
  height: 24px;
}

.topGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.cardTitle {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #6f6f6f;
}

.cardValue {
  font-size: 3.6rem;
  font-weight: 500;
  color: #1a1e26;
}

.innerPageSection {
  margin-top: 5rem;
}

.innerTitle {
  font-size: 21px;
  margin-bottom: 2rem;
  font-weight: 500;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        &:last-child {
          width: 10%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &:last-child {
          width: 10%;
        }
        &:first-child {
          width: 25%;
          font-weight: 600;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
          border-radius: 4px;
        }
      }
    }
  }
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -16px;
  width: calc(100% + 32px);
  padding: 16px;
  &:first-child {
    margin-top: -16px;
  }
  &:last-child {
    margin-bottom: -16px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #c5c5c5;
  }

  .label {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .value {
    font-size: 1.6rem;
    font-weight: 400;
  }
}

.itemsList {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 10px 10px;
          font-size: 13px;
          font-weight: 400;
          color: #6f6f6f;
          text-align: left;
          &:last-child {
            text-align: right;
            width: 10%;
          }
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        td {
          padding: 12px 10px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          text-align: left;
          &:last-child {
            text-align: right;
            width: 10%;
          }
          &:not(:first-child) {
            &,
            * {
              white-space: nowrap;
            }
          }
        }
        &:hover {
          background-color: #f5f5f5;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }
  }
}

.status {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
  background-color: #f5f5f5;
  color: #6f6f6f;
}

.active {
  color: #006908;
  background-color: #d7f7c2;
}
