.ql-container {
  max-height: 280px;
  min-height: 180px;
  background-color: #f6f9fc;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ql-toolbar {
  border: 1px solid #cfd7df;
  border-bottom: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ql-tooltip {
  top: 0 !important;
  left: 8px !important;
}

.ql-editor hr {
  border: none;
  border-top: 1px solid #666666;
  margin: 1rem 0;
  height: 0;
  padding: 0;
  * {
    display: none;
  }
}