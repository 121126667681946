.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 84px 0;
  .loader {
    font-size: 22px;
    font-weight: 500;
    color: #1a1e26;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #1a1e26;
  }
  .subTitle {
    font-size: 14px;
    line-height: 24px;
    color: #6b6b6b;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
  button {
    margin-top: 10px;
    padding-left: 38px;
    padding-right: 38px;
  }
}
