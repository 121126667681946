.payoutInfoContainer {
  position: relative;
  vertical-align: middle;
  line-height: 20px;
}

.payoutInfoIcon {
  cursor: pointer;
  height: 20px;
  &:hover {
    & + .payoutInfo {
      display: block;
    }
  }
}

.payoutInfo {
  display: none;
  position: absolute;
  &.bottom {
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
  }
  &.top {
    bottom: 26px;
    left: 50%;
    transform: translateX(-50%);
  }
  background: #fff;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.5);
  width: 300px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4;
  color: #444557;
  border: 1px solid #e3e3e3;
  z-index: 10000;
}
