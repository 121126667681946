.pageLayout {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;

  height: 100vh;
  width: 100%;
  overflow-y: hidden;

  &.notOpen {
    grid-template-columns: auto 1fr;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: overlay;
  position: relative;
}

.content {
  flex: 1;
  max-width: 130rem;
  margin: 0 auto;
  width: 100%;
  padding: 34px 24px 60px;
}
