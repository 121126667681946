.react-datepicker {
  font-size: 1.2rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.1rem;
  line-height: 2.1rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.3rem;
}

.react-datepicker__header {
  padding: 10px 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: -80px !important;
}
