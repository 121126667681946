.editorWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: #313131;

  font-family: unset !important;
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}