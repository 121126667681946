.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContainer {
  background-color: #fff;
  padding: 2rem; // Increase padding for the entire modal
  border-radius: 4px;
  max-width: 80%;
  position: relative;
  z-index: 1001;
}

.modalHeader,
.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader {
  padding-bottom: 2rem;
  border-bottom: 1px solid #efefef;
  margin-left: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: calc(100% + 4rem);

  display: flex;
  justify-content: space-between;
  align-items: center;

  &.isUpsell {
    border-bottom: none;
    padding-bottom: 0;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
  }
}

.modalFooter {
  padding-top: 2rem;
  border-top: 1px solid #efefef;
  margin-left: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: calc(100% + 4rem);
}

.modalBody {
  padding: 2rem;
  margin-left: -2rem;
  max-width: calc(100% + 4rem);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 2px;

  &.isUpsellBody {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.modalFooterRight {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.modalButton {
  margin-left: 1rem;
}

.modalCloseButton {
  cursor: pointer;
  transition: 0.3s ease color; // Add a transition effect for the hover
  color: #535353;
}

.modalCloseButton:hover {
  color: #000000;
}
