// styles.module.scss

.ratingContainer {
  margin-top: 32px;
  padding-top: 28px;
  * {
    white-space: nowrap;
  }
  .ratingHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .left {
      font-weight: 500;
      font-size: 22px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 6px;
      .revs {
        font-size: 13px;
        color: #353535;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.stars {
  margin-right: 8px;
  width: 42px;
  font-size: 13px;
}

.bar {
  flex-grow: 1;
  height: 20px;
  border-radius: 2px;
  border: rgb(169, 169, 169) 1px solid;
  margin-right: 8px;

  &Inner {
    border-radius: 1px;
    height: 100%;
    background-color: #5469d4;
  }
}

.percentage {
  font-size: 12px;
  width: 26px;
  text-align: right;
}

.reviews {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .review {
    padding: 16px;
    border-radius: 4px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
      .nameContainer {
        .name {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
      .date {
        font-size: 12px;
        color: #353535;
      }
    }
    .comment {
      font-size: 14px;
      line-height: 1.5;
      color: #353535;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }
    
    .commentExpanded {
      white-space: break-spaces;
      overflow: visible;
      display: unset;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
    }

    .button {
      font-size: 14px;
      color: #5469d4;
      cursor: pointer;
      margin-top: 8px;
      background: none;
      outline: none;
      border: none;
    }
  }
}
