.selectContainer {
  position: relative;
  display: inline-block;
}

.select {
  appearance: none;

  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.17);
  color: #1a1e26;

  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  width: 100%;
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: #3447ef;
    box-shadow: 0 0 0 1px #3447ef;
  }

  &::-ms-expand {
    display: none;
  }

  &.isError {
    border-color: #e54f4f;
    box-shadow: 0 0 0 1px #e54f4f;
  }
}

.arrow {
  content: "";
  position: absolute;
  top: 46%;
  right: 12px;
  transform: translateY(-50%);
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translateY(-50%);
}
