.pageContainer {
  overflow: overlay;
  max-height: 100vh;
  &.fromProdPage {
    max-height: unset;
    overflow: hidden;
  }
}

.footer {
  height: 58px;
  width: 100%;
  padding-bottom: 8px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  font-size: 14px;
  a {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
}
