.variantsSelector {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  .variant {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #424242;
    box-shadow: inset 0px 0px 0px 1px #424242;
  }

  .variantTopContainer {
    align-items: center;
    padding: 0 14px;
    display: flex;
    height: 46px;
    gap: 14px;
    cursor: pointer;
  }

  .variantName,
  .variantPrice {
    font-size: 13px;
    color: #1a1a1a;
    font-weight: 500;
  }

  .variantPrice {
    font-size: 14px;
    margin-left: auto;
  }

  .variantCircle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    .variantCircleSmall {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .active {
    border: 1px solid #3447ef;
    box-shadow: inset 0px 0px 0px 1px #3447ef;

    .variantName,
    .variantPrice {
      color: #3447ef;
    }

    .variantCircle {
      border-color: #3447ef;

      .variantCircleSmall {
        background-color: #3447ef;
        border-color: #3447ef;
      }
    }
  }

  .variantDescription {
    padding: 10px 14px;
    font-size: 13px;
    color: #424242;
    border-top: 1px solid #424242;
  }
}
