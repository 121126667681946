.socialSharing {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.socialSharing__link {
  color: inherit;
  text-decoration: none;
  font-size: 1.5em;
  transition: color 0.2s;
}

.socialSharing__link:hover {
  color: #888;
}
