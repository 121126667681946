.nav-bar__container {
  display: flex;
  justify-content: center;
  /* flex-shrink: 0; */
  width: 100%;

  box-shadow: #d3d3d3 0-1px 0 inset;

  z-index: 300;
}

.nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 72px;
  padding: 0 24px;
  margin: 0;
}

.nav-bar__buttons {
  display: flex;
  margin-left: 24px;
}