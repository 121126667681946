:root {
  --gjs-left-width: 20%;

  --gjs-main-color: #444;
  --gjs-primary-color: #444;
  --gjs-secondary-color: #ddd;
  --gjs-tertiary-color: #323976;
  --gjs-quaternary-color: #3447ef;
}

.gjs-block-label {
  font-size: 10px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
}

.gjs-four-color {
  color: #3447ef !important;
}

.gjs-four-color-h:hover {
  color: #474d81 !important;
}

.gjs-two-color {
  color: #ededed !important;
}

.gjs-one-bg {
  background-color: #313131 !important;
}

.gjs-three-bg {
  background-color: #41449a !important;
}

.gjs-sm-label {
  color: #ededed !important;
}

.gjs-sm-sector-title {
  padding-left: 8px !important;
}

.gjs-off-prv {
  padding: 12px !important;
  font-size: 16px !important;
  background-color: #313131 !important;
}

.gjs-clm-header,
.gjs-sm-sector-label,
.gjs-field,
.gjs-clm-sels-info,
.gjs-trt-trait,
.gjs-traits-label,
.gjs-layer,
.gjs-title,
.gjs-sm-label {
  font-size: 10px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
}

.gjs-sm-label {
  font-weight: 400 !important;
}

.gjs-trt-header,
.gjs-sm-header {
  font-size: 10px !important;
  font-family: Inter !important;
  font-weight: 400 !important;
}

.gjs-block {
  width: unset !important;
  height: unset !important;
  min-height: unset !important;
  flex: 1 !important;
  padding: 10px !important;
  margin: 0 !important;
  svg {
    max-width: 30px !important;
    max-height: 30px !important;
  }
}

.gjs-pn-panel {
  width: 170px !important;
}

.gjs-blocks-c {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  gap: 4px !important;
  padding: 10px 3px !important;
}