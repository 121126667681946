.date {
  font-size: 14px;
  font-weight: 500;
  color: #1a1e26;
}

.bigPageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.OrderTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 600;
  color: #1a1e26;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.country {
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  display: flex;
  align-items: center;
  gap: 4px;
  .emoji {
    font-size: 24px;
  }
}

.pageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  align-items: flex-start;
  .topGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    .stat {
      display: flex;
      align-items: center;
      gap: 18px;
    }
    .icon {
      height: 54px;
      width: 54px;
      border-radius: 14px;
      background-color: #cdfdcc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .statTitle {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a1e26;
    }
    .statValue {
      font-size: 3.2rem;
      font-weight: 600;
      color: #1a1e26;
    }
  }
}

.cardTitle {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.5rem;
}

.ratingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ratingNumber {
    font-size: 14px;
    margin-left: 10px;
    margin-top: -3px;
  }
}

.cardTitle {
  font-size: 15px;
  font-weight: 500;
  color: #1a1e26;
  margin-top: 24px;
  margin-bottom: 8px;
}

.review {
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  line-height: 1.5;
  color: #1a1e26;
}

.customerContainer {
  font-size: 14px;
  font-weight: 600;
  color: #1a1e26;
  display: flex;
  align-items: center;
  gap: 10px;
  &:hover {
    cursor: pointer;
    color: #3447ef;
  }
  img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
  }
  .customerText {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.noReview {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  text-align: center;
  padding: 20px 0;
}

.productNameContainer {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 4px;
  }
  .ellipsis {
    // ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    color: #1a1e26;
    margin-bottom: 8px;
  }
  .price {
    font-size: 14px;
    font-weight: 500;
    color: #1a1e26;
  }
  &:hover {
    cursor: pointer;
    * {
      color: #3447ef;
    }
  }
}

.orderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #1a1e26;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &:last-child {
    font-size: 16px;
    font-weight: 600;
  }
}

.onHold {
  font-size: 11px;
  font-weight: 500;
  background-color: #e9e8f1;
  padding: 6px 16px;
  border-radius: 40px;
  color: #1a1e26;
  margin-left: 8px;
}

.divider {
  border: none;
  border-left: 1px solid rgba(#1a1e26, 0.2);
  margin: 0 8px;
  height: 24px;
}

.deleteModalText {
  font-size: 1.4rem;
  font-weight: 500;
  color: #1a1e26;
  line-height: 1.7;
}

.taxId {
  font-size: 14px;
  color: #333 !important;
}