.textArea {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0px 3px 4px -4px rgba(0, 0, 0, 0.17);
  color: #1a1e26;
  font-size: 12px;
  border-radius: 4px;
  line-height: 1.5;
  padding: 12px;
  width: 100%;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #3447ef;
    box-shadow: 0 0 0 1px #3447ef;
  }

  &::placeholder {
    color: #afb7c4;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
  }
  &.isError {
    border-color: #e54f4f;
    box-shadow: 0 0 0 1px #e54f4f;
  }

  &.disabled {
    background-color: #f5f6f7 !important;
    box-shadow: none;
    color: #c6cbd3 !important;
    pointer-events: none;
  }
}
