.page2 {
  min-height: calc(121vh - 58px);
  .hero {
    width: 100%;
    padding: 90px 60px;
    @media only screen and (max-width: 912px) {
      padding: 60px 30px 90px;
    }
  }
  .heroContent {
    max-width: 1300px;
    margin: 0 auto;
    h1 {
      font-weight: 600;
      font-size: 34px;
      margin-bottom: 20px;
      max-width: 800px;
      // ellipsis in 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 20px;
      max-width: 800px;
      // ellipsis in 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .ratingSales {
      display: flex;
      align-items: center;
      gap: 6px;
      flex-flow: wrap;
    }
    .rating {
      display: flex;
      align-items: center;
      gap: 6px;
      flex-flow: wrap;
    }
    .revs,
    .sales {
      font-size: 20px;
      font-weight: 500;
      @media only screen and (max-width: 912px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .userContainer {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 30px;
      font-size: 15px;
      .user {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .content {
    padding: 60px;
    @media only screen and (max-width: 912px) {
      padding: 60px 30px;
    }
    @media only screen and (max-width: 600px) {
      padding: 60px 20px;
    }
    @media only screen and (max-width: 400px) {
      padding: 60px 10px;
    }
  }

  .contentBody {
    display: grid;
    grid-template-columns: 1fr 420px;
    gap: 40px;
    max-width: 1300px;
    align-items: flex-start;
    margin: 0 auto;
    a {
      text-decoration: underline;
    }

    @media only screen and (max-width: 912px) {
      grid-template-columns: unset;
      gap: 50px;
      padding-top: 50px;
      // switch order of grid items
      .left {
        order: 2;
      }
    }

    .left {
      h1 {
        font-size: 3.2rem;
        font-weight: 500;
        margin-bottom: 4rem;
      }
      p {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 24px;

        @media only screen and (max-width: 912px) {
          font-size: 15px;
        }
      }
      hr {
        margin: 4rem 0;
      }
      iframe,
      img {
        width: 100%;
        height: auto;
        min-height: 400px;
      }
      ul,
      ol {
        margin-left: 1.5rem;
        margin-bottom: 1rem;
        margin-left: 0;

        li {
          font-size: 16px;
          &:not(:last-child) {
            margin-bottom: 18px;
          }
        }
      }
      a.added-button {
        background-color: #3447ef !important;
        color: #fff;
        border-radius: 4px;
        padding: 10px 14px;
        margin: 0 4px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;

        &:hover {
          background-color: #5469d4;
        }
      }
    }
    .right {
      border-radius: 4px;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.12);
      margin-top: -170px;

      padding: 2px;

      .imageContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 300px;
        width: 100%;
        overflow: hidden;
        border-radius: 3px 3px 0 0;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        border: 1px solid #dfdfdf;

        @media only screen and (max-width: 912px) {
          max-height: 500px;
        }

        .bigImage {
          position: absolute;
          bottom: 10px;
          transition: all 0.3s;
          right: 10px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 50%;
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          opacity: 0;
        }

        .arrowLeft,
        .arrowRight {
          position: absolute;
          z-index: 1;
          cursor: pointer;
          transition: all 0.3s;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 50%;
          padding: 8px;
          opacity: 0;
          pointer-events: none;
          @media only screen and (max-width: 912px) {
            opacity: 1;
          }
        }

        &:hover .arrowLeft,
        &:hover .bigImage,
        &:hover .arrowRight {
          opacity: 1;
          pointer-events: auto;
        }

        .arrowLeft {
          left: 10px;
        }

        .arrowLeft:hover {
          transform: translateX(-3px);
        }

        .arrowRight {
          right: 10px;
        }

        .arrowRight:hover {
          transform: translateX(3px);
        }

        .image {
          height: auto;
          width: 100%;
          object-fit: cover;
        }
      }

      .rightContainer {
        margin-top: 24px;
        padding: 0 18px 0;
      }

      button {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .revsContainer {
    margin-top: -18px;
    max-height: 100vh;
    overflow-y: overlay;
    margin-left: -18px;
    width: calc(100% + 36px);
    padding: 0 18px 24px;
  }

  .salesContainer {
    margin-bottom: 24px;
  }
}

.fullscreenImage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  padding: 0 18px;
  @media only screen and (max-width: 912px) {
    padding: 4px;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  .arrowLeft,
  .arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    padding: 8px;
    opacity: 0;
    pointer-events: none;
    @media only screen and (max-width: 912px) {
      opacity: 1;
    }
  }

  &:hover .arrowLeft,
  &:hover .bigImage,
  &:hover .arrowRight {
    opacity: 1;
    pointer-events: auto;
  }

  .arrowLeft {
    left: 10px;
  }

  .arrowLeft:hover {
    transform: translateY(-50%) translateX(-3px);
  }

  .arrowRight {
    right: 10px;
  }

  .arrowRight:hover {
    transform: translateY(-50%) translateX(3px);
  }

  .image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .img {
    position: relative;
    z-index: 1;
  }
}

.xContainer {
  z-index: 1;
  position: absolute;
  top: 20px;
  transition: all 0.3s;
  right: 40px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
