.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.hideContainer {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  
}

.content {
  padding: 20px 20px 40px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  text-align: center;
  .illustrationContainer {
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(329deg, #001aff, #d7dbff);
    height: 80px;
    width: 80px;
  }
  .title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1a1e26;
  }
  .subTitle {
    font-size: 16px;
    margin-bottom: 40px;
    color: #51565d;
    line-height: 1.7;
  }

  .stepsContainer {
    border-top: 1px solid rgba(#1a1e26, 0.2);
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .step {
      display: flex;
      padding: 20px 0;
      gap: 16px;
      border-bottom: 1px solid rgba(#1a1e26, 0.2);
      .checkContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 2px solid #dbdbdb;
        flex-shrink: 0;
        position: relative;
        top: -4px;
        svg {
          display: none;
        }
      }
      .stepTitle {
        font-size: 21px;
        font-weight: 600;
        color: #1a1e26;
      }
      .stepSubTitle {
        margin-top: 10px;
        font-size: 14px;
        color: #51565d;
        line-height: 1.4;
        margin-bottom: 14px;
      }
      button {
        height: 30.75px;
        padding: 0.6375rem 1.125rem;
      }

      &.active {
        .checkContainer {
          border: 1px solid #39c855;
          background-color: #39c855;
          top: 0;
          svg {
            display: block;
          }
        }
        .textContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .stepTitle {
          text-decoration: line-through;
          color: #818792;
          font-weight: 400;
          margin-bottom: 0;
        }
        .stepSubTitle,
        button {
          display: none;
        }
      }
    }
  }
}
