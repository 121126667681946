.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.filter {
  width: 280px;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: left;
        &:first-child {
          width: 16%;
        }
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 19%;
          text-align: center;
        }
        &:nth-child(6) {
          width: 10%;
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:first-child {
          width: 16%;
          font-weight: 600;
        }
        &:nth-child(4) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(5) {
          width: 10%;
          text-align: center;
        }
        &:nth-child(6) {
          width: 10%;
          text-align: center;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        font-weight: 600;
      }
    }
  }
}
