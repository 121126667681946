.profilePageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  gap: 26px;
  padding: 0 24px 60px;
}

.divider {
  height: 1px;
  background-color: #e4e4e4;
  margin-bottom: 20px;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.pageContainer {
  display: flex;
  max-width: 1020px;
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // width: 100px;
    > * {
      cursor: pointer;
      &,
      a {
        height: 36px;
        display: flex;
        align-items: center;
      }
      a {
        padding: 0 18px;
        width: 100%;
      }
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      &:hover {
        background-color: #f0f0f0;
        // color: #007bff;
      }
    }
  }

  .content {
    padding-left: 80px;
    flex: 1;
  }

  .activeItem {
    background-color: #f0f0f0;
    * {
      color: #007bff;
    }
  }
}
