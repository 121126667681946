.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.filter {
  width: 280px;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: center;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(2) {
          text-align: left;
        }
        &:first-child {
          width: 20%;
          text-align: left;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        text-align: center;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(2) {
          text-align: left;
        }
        &:first-child {
          width: 20%;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .name {
          max-width: 420px;
          font-weight: 600;
          text-align: left;
          // ellipsis
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.searchInputContainer {
  width: 400px;
  display: flex;
  align-items: center;
  gap: 10px;
  .seachInput {
    flex: 1;
  }
}

.count {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  margin-top: 10px;
}

.productNameContainer {
  display: flex;
  align-items: center;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 4px;
    object-fit: cover;
  }
}
