.thankYouPage {
  overflow-x: hidden;
  padding: 0 1rem 8rem;
  background: #f4f4f8;
}

.thankYouPageNoUser {
  height: 101vh;
}

.mainHeader {
  padding-top: 30px;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  h1 {
    font-size: 5rem;
    font-weight: 700;
    margin: 1rem 0 0.6rem;
  }
  h2 {
    font-size: 2.1rem;
    font-weight: 500;
  }
  margin-bottom: 1rem;
  text-align: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #d2d2d2;

  .downloadButton {
    margin-top: 3rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    height: 42px;
    border-radius: 50px;
    background-color: #6c63ff;
    border: 2px solid #8079ff;
    font-size: 1.3rem;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #4a43d5;
      border: 2px solid #5f58e4;
      color: #fff;
    }
    &:active {
      background-color: #3b35b5;
      color: #fff;
    }
  }
}

.productContainer {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  max-width: 250px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  h3 {
    font-size: 1.9rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.imageContainer {
  height: 222px;
  width: 222px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  margin-bottom: 1rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 1.1rem;
  margin-top: 0.8rem;
  border-top: 1px solid #d2d2d2;
  .amount {
    font-size: 1.7rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

.user {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.8rem;
  .userImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.bodyContent {
  padding: 0 1rem;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 40px;
}

.topArea {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 1.4rem;
  max-width: 250px;
}

.reviewContainer {
  padding: 10px 14px;
  flex: 1;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .reviewText {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .reviewTextContainer {
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 2rem;
    textarea {
      min-height: 100px;
      max-height: 129px;
    }
  }
}

.share {
  padding: 10px 14px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-width: 250px;
  // margin: 0 auto;
  // margin-top: 20px;
  text-align: center;
  .label {
    font-weight: 400;
    font-size: 1.4rem;
    margin-bottom: 1.4rem;
  }
}

.topAreaContainer {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: flex-start;
  gap: 4rem;

  @media only screen and (max-width: 912px) {
    grid-template-columns: 1fr;
    gap: 2rem;
    .creatorMessageContainer {
      order: 1;
    }
    .topArea {
      order: 2;
      display: flex;
      flex-wrap: wrap;
      max-width: unset;
      flex-direction: row;
      align-items: start;
      & > * {
        max-width: 250px;
      }
    }
  }

  .creatorMessageContainer {
    h1 {
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 4rem;
    }
    p {
      font-size: 17px;
      line-height: 1.4;
      margin-bottom: 24px;

      @media only screen and (max-width: 912px) {
        font-size: 15px;
      }
    }
    hr {
      margin: 4rem 0;
    }
    iframe {
      width: 100%;
      height: auto;
      min-height: 400px;
    }
  }
}

.alsoBought {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.prodButton {
  width: 100%;
  button {
    margin-top: 10px;
    width: 100%;
  }
}

.signupPopup {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
  background: white;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.027);
  padding: 20px;
  display: flex;
  flex-direction: column;

  max-width: 400px;
  width: 100%;
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
  }

  .buttons {
    display: flex;
    justify-content: stretch;
    gap: 10px;
    > * {
      flex: 1;
      > * {
        width: 100%;
      }
    }
  }
}

.checkContainer {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: rgb(77, 173, 77);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 2rem;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8 !important;
  margin-bottom: 2rem !important;
  max-width: 560px;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
}

.title {
  margin-bottom: 2rem !important;
}
