.pageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  padding: 0 24px 60px;
}

.tableContainer {
  overflow: overlay;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #fff;
}

.filter {
  width: 280px;
}

.productsTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        padding: 20px 0;
        font-size: 13px;
        font-weight: 400;
        color: #6f6f6f;
        text-align: center;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(2) {
          text-align: left;
        }
        &:first-child {
          width: 20%;
          text-align: left;
        }
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;
      td {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: left;
        width: 13%;
        text-align: center;
        &:last-child {
          text-align: right;
          width: 10%;
        }
        &:nth-child(2) {
          text-align: left;
        }
        &:first-child {
          width: 20%;
          font-weight: 600;
          text-align: left;
          // ellipsis
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #c5c5c5;
      }

      .productNameContainer {
        font-weight: 600;
      }
    }
  }
}

.searchInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  .seachInput {
    flex: 1;
    width: 310px;
  }
}

.count {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  margin-top: 10px;
}

.modalText {
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 500;
  color: #000;
  &:not(.expandModalText) {
    max-width: 440px;
  }
}

.dateToggler,
.productsToggler {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #1a1e26;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  span {
    max-width: 240px; /* Set max width */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
  }
}

.productsFilter,
.datesFilter {
  position: relative;
}

.productsListContainer {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 10%);
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  z-index: 100;
  overflow: hidden;
  margin-top: 8px;
  width: 400px; /* Updated width */

  .productsFilterList {
    max-height: 300px;
    overflow-y: auto;

    .productsFilterItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      .productNameContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;

        img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          object-fit: cover;
        }

        .productName {
          font-size: 14px;
          font-weight: 500;
          color: #1a1e26;
          max-width: 200px; /* Set max width */
          white-space: nowrap; /* Prevent wrapping */
          overflow: hidden; /* Hide overflowing text */
          text-overflow: ellipsis; /* Add ellipsis for overflow */
        }
      }

      .productPrice {
        font-size: 14px;
        font-weight: 400;
        color: #6f6f6f;
        margin-left: auto; /* Ensures price aligns to the right */
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
}

.loadingState,
.emptyState,
.emptySearchState {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  padding: 20px 0;
}

.form {
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
  .label {
    font-size: 13px;
    font-weight: 500;
    color: #6f6f6f;
    padding: 6px 0;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.clearIcon {
  cursor: pointer;
  color: #6f6f6f;
  &:hover {
    color: #1a1e26;
  }
}

.pageTabsContainer {
  display: flex;
  align-items: center;
  max-width: 300px;
  margin-bottom: 24px;

  .tab {
    flex: 1;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 2px solid #d9d9d9;
    color: #1a1e26;
    font-weight: 500;

    &.active {
      border-bottom: 2px solid #3447ef;
      color: #3447ef;
      font-weight: 600;
    }

    &.disableTab {
      pointer-events: none;
    }
  }
}
