// SingleImageUpload.module.scss
.singleImageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  border: 2px dashed #aaa;
  border-radius: 4px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #424770;
  &:hover {
    background-color: #f5f5f5;
    border-color: #9098a9;
  }
}

.uploadInput {
  display: none;
}

.uploadLabel {
  display: inline-block;
  flex: 1;
  cursor: pointer;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}

.plusSign {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-right: 8px;
}

.imageInfoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;

  color: #424770;
}

.imageContainer {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 1rem;
}

.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageName {
  margin-bottom: 0.25rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
  font-size: 12px;
}

.imageSize {
  font-size: 11px;
  color: #777;
}

.removeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: #dc3545;
  transition: color 0.3s ease;

  &:hover {
    color: #b22234;
  }
}
