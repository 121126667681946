.fileUpload {
  display: flex;
  align-items: center;
  width: 100%;
}

.fileInput {
  display: none;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1.5px dashed #c4c4c4;
  padding: 12px;
  height: 52px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  color: #424770;
  &:hover {
    background-color: #f5f5f5;
    border-color: #9098a9;
  }
}

.uploaded {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: default;
  &:hover {
    background-color: #f8f9fa;
    border-color: #ddd;
  }
}

.buttonContent {
  display: flex;
  align-items: center;
}

.plusSign {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  margin-right: 8px;
}

.uploadedContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  font-size: 12px;
}

.fileIcon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.fileSize {
  margin-left: auto;
  margin-right: auto;
}

.removeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  color: #dc3545;
  transition: color 0.3s ease;

  &:hover {
    color: #b22234;
  }
}
