a.added-button {
  text-decoration: none !important;
  background-color: #3447ef;
  color: #fff;
  border-radius: 4px;
  padding: 10px 14px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;

  &:hover {
    background-color: #5469d4;
  }
}
