.profilePageContainer {
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 1290px;
  margin: -34px auto 0;

  display: grid;
  gap: 26px;
  padding: 0 24px 60px;
}

.stripeSection {
  margin-top: 50px;
}

.divider {
  height: 1px;
  background-color: #e4e4e4;
  margin-bottom: 20px;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.pageContainer {
  display: flex;
  max-width: 1020px;
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 5px;
    // width: 100px;
    > * {
      cursor: pointer;
      &,
      a {
        height: 36px;
        display: flex;
        align-items: center;
      }
      a {
        padding: 0 18px;
        width: 100%;
      }
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      &:hover {
        background-color: #f0f0f0;
        // color: #007bff;
      }
    }
  }

  .content {
    padding-left: 80px;
    flex: 1;
  }

  .activeItem {
    background-color: #f0f0f0;
    * {
      color: #007bff;
    }
  }
}

// ––––––– Settings –––––––

.formsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .avatarText {
    > * {
      display: block;
    }
    span {
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .avatarPreview {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    border: 1px solid #e3e3e3;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fileInput {
    display: none;
  }
}

.urlGrid {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  .urlPrefix {
    font-size: 13px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .url {
    flex: 1;
  }
}

.text {
  font-size: 14px;
  line-height: 1.7;
  b {
    font-weight: 500;
    color: #3447ef;
  }
}

.stripeBank {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid #cdcdcd;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  .iconContainer {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 22px;
      height: 22px;
    }
  }
  .bankText {
    margin-left: 12px;
    .bankTitle {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 3px;
      * {
        vertical-align: middle;
      }
    }
    .bankDescription {
      font-size: 13px;
      color: #666;
    }
  }
  .buttonContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.bigContainer {
  display: grid;
  grid-template-columns: 1fr 360px;
  gap: 2rem;
}

.prodPageContainer {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  height: 500px;
  &.thanks {
    height: 410px;
  }
  &.check {
    height: 414px;
    // height: 443px;
  }
  overflow: hidden;
  position: relative;

  &:hover {
    overflow-y: overlay;
  }
  > * {
    pointer-events: none;
    transform-origin: top left;
    transform: scale(0.435);
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    height: 229%;
    overflow: hidden;
  }
  // img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
}

.cant {
  font-size: 12px;
  color: #666;
}

.productsList {
  margin-top: 2rem;
}

.productItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .productPrice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000000;
  }

  & > *:first-child {
    flex-shrink: 0;
  }
}

.productNameContainer {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }

  .productName {
    font-size: 1.4rem;
    font-weight: 500;
    // ellipsis with 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 250px;
  }
}

.emptyList,
.noProdSelected {
  font-size: 1.1rem;
  color: #000000;
  margin-top: 2rem;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.notificationContainer {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  border-radius: 6px;
  background: #1f1d2a;
  color: #f2f1fa;
  display: flex;
  gap: 6px;
  padding: 14px 14px 14px 16px;
  width: 370px;
  opacity: 0;
  transform: translateX(50px);
  transition: all 0.3s ease;
  display: none;
  &.visible {
    display: flex;
    opacity: 1;
    transform: translateX(0);
  }
  img {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    border-radius: 38px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .notifText {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
    .subTitle {
      font-size: 12px;
      line-height: 15px;
      color: #c2c1ca;
    }
    .buttonsContainer {
      margin-top: 3px;
      button {
        height: 27px;
        padding: 0.3375rem 1.125rem;
        color: #f2f1fa;
      }
    }
  }
}

.usernameHolder {
  position: relative;
  .suffix {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24px;
    height: 34px;
    right: 1px;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    color: #51565d;
    border-left: 1px solid #e5e5e5;
    padding: 0 12px 0 12px;
    background-color: #f6f8fa;
  }

  .loading,
  .succeeded,
  .failed {
    position: absolute;
    right: 1px;
    bottom: -16px;

    font-size: 10px;
    color: #51565d;
  }

  .succeeded {
    font-weight: 600;
    color: #2ecc71;
  }

  .failed {
    font-weight: 600;
    color: #e74c3c;
  }
}

.deleteModalText {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
  max-width: 480px;
  line-height: 1.7;
}
