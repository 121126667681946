.modalContent {
  width: 100%;
  min-width: 380px;
  overflow-y: overlay;
  display: grid;
  gap: 1rem;
}

.colorComponent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  &:hover {
    background-color: #eceff1;
    border-color: #a6aee9;
  }
  &:active {
    background-color: #e4e7ed;
    border-color: #9098a9;
  }
  .color {
    width: 24px;
    height: 24px;
    margin-right: 0.7rem;
    border-radius: 3px;
    border: 1px solid #ddd;
  }
  .code {
    font-size: 12.5px;
    color: #424770;
  }
  .pickerContainer {
    z-index: 9999;
    position: absolute;
    .cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: default;
    }
  }
}

.buttonContainer {
  display: inline-flex;
  margin-bottom: 1rem;
}