// ErrorBanner.module.scss

.errorBanner {
  display: flex;
  background-color: #fce4e6;
  color: #982531;
  padding: 10px 15px;
  border: 1px solid #ebafb5;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 15px;

  &.info {
    background-color: #e7f5ff;
    color: #0c63e4;
    border: 1px solid #b6e2ff;
  }
}

.iconContainer {
  margin-right: 6px;
}

.message {
  margin: 0;
  font-size: 14px;
  margin-left: 0.5rem;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: #721c24;
}
