.radioInput {
  .radio {
    position: absolute;
    opacity: 0;

    // Text
    & + label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 18px;
      padding-left: 28px;
      color: #666; // Label colour

      // Radio button
      &:before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background: transparent;
        width: 18px;
        height: 18px;
        border: 1px solid #a2a5ac;
        border-radius: 50%;
        transition: 200ms ease-in-out all;
      }

      // Check
      &:after {
        position: absolute;
        display: block;
        left: 8px;
        top: 8px;
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        transform: scale(0);

        transition: 200ms ease-in-out all;
      }
    }

    // Hover
    & + label:hover {
      color: #333;
    }

    // Focus
    & + label:focus {
      outline: none;
    }

    // Checked
    &:checked {
      & + label {
        &:before {
          border-color: #3447ef;
          background: #3447ef;
        }
        &:after {
          transform: scale(1.8);
          background: #fff;
        }
      }
    }

    // Disabled
    &:disabled {
      & + label {
        &:before {
          background: #f2f2f2; // Disabled colour
          border-color: #a2a5ac;
        }
        &:after {
          background-color: #cecece;
        }
      }
    }

    // Disabled Checked
    &:checked:disabled {
      & + label {
        &:before {
          background: #f2f2f2;
          border-color: #a2a5ac;
        }
        &:after {
          background-color: #cecece;
        }
      }
    }
  }
}
