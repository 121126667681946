.page3 {
  min-height: calc(121vh - 58px);
  position: relative;
  padding-bottom: 60px;
  .hero {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    text-align: center;
    padding: 120px 60px 130px;
    @media only screen and (max-width: 912px) {
      padding: 100px 30px 60px;
    }
    h1 {
      font-weight: 600;
      font-size: 43px;
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 28px;
      text-align: center;
      // ellipsis in 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    h3 {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.7;
      margin-bottom: 20px;
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
      // ellipsis in 2 lines
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    gap: 50px;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 60px;
    @media only screen and (max-width: 912px) {
      padding: 0 20px;
      gap: 30px;
    }
    @media only screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 500px;
    margin-bottom: 40px;
    width: 100%;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border: 1px solid #dfdfdf;

    @media only screen and (max-width: 912px) {
      max-height: 500px;
    }

    .bigImage {
      position: absolute;
      bottom: 10px;
      transition: all 0.3s;
      right: 10px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 50%;
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
    }

    .arrowLeft,
    .arrowRight {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: all 0.3s;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 50%;
      padding: 8px;
      opacity: 0;
      pointer-events: none;
      @media only screen and (max-width: 912px) {
        opacity: 1;
      }
    }

    &:hover .arrowLeft,
    &:hover .bigImage,
    &:hover .arrowRight {
      opacity: 1;
      pointer-events: auto;
    }

    .arrowLeft {
      left: 10px;
    }

    .arrowLeft:hover {
      transform: translateX(-3px);
    }

    .arrowRight {
      right: 10px;
    }

    .arrowRight:hover {
      transform: translateX(3px);
    }

    .image {
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  .userImage {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #1a1e26;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .right {
    .priceContainer {
      font-size: 32px;
      font-weight: 500;
      color: #1a1e26;
      margin-bottom: 20px;
    }

    button {
      display: block;
      width: 100%;
    }
  }

  .left {
    h1 {
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 4rem;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 24px;

      @media only screen and (max-width: 912px) {
        font-size: 15px;
      }
    }
    hr {
      margin: 4rem 0;
    }
    iframe,
    img {
      width: 100%;
      height: auto;
      min-height: 400px;
    }
    ul,
    ol {
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      margin-left: 0;

      li {
        font-size: 16px;
        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }
    a.added-button {
      background-color: #3447ef !important;
      color: #fff;
      border-radius: 4px;
      padding: 10px 14px;
      margin: 0 4px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      display: inline-block;

      &:hover {
        background-color: #5469d4;
      }
    }
  }
}

.fullscreenImage {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
  padding: 0 18px;
  @media only screen and (max-width: 912px) {
    padding: 4px;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  .arrowLeft,
  .arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    padding: 8px;
    opacity: 0;
    pointer-events: none;
    @media only screen and (max-width: 912px) {
      opacity: 1;
    }
  }

  &:hover .arrowLeft,
  &:hover .bigImage,
  &:hover .arrowRight {
    opacity: 1;
    pointer-events: auto;
  }

  .arrowLeft {
    left: 10px;
  }

  .arrowLeft:hover {
    transform: translateY(-50%) translateX(-3px);
  }

  .arrowRight {
    right: 10px;
  }

  .arrowRight:hover {
    transform: translateY(-50%) translateX(3px);
  }

  .image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .img {
    position: relative;
    z-index: 1;
  }
}

.xContainer {
  z-index: 1;
  position: absolute;
  top: 20px;
  transition: all 0.3s;
  right: 40px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mainCta {
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  &.inBottomBar {
    height: 40px;
    font-size: 15px;
    padding: 10px 20px;
  }
}

.floatingBottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-top: 1px solid #dfdfdf;
  padding: 10px 20px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  transform: translateY(0);
  opacity: 1;

  visibility: visible;

  .bottomPayWant {
    max-width: 320px;
    display: grid;
    gap: 10px;
  }
}

.floatingBottomBarHidden {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.payWantCta {
  display: flex;
  align-items: end;
  gap: 1rem;
  input {
    height: 52px !important;
  }
  * {
    margin-bottom: 0 !important;
  }

  margin-top: -12px;

  @media only screen and (max-width: 912px) {
    display: none;
  }
}
