@font-face {
  font-family: "Inter";
  font-weight: 900;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  font-style: normal;
  src: url("./fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  font-style: normal;
  src: url("./fonts/Inter/Inter-Thin.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Oswald:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Raleway:wght@400;700&family=PT+Sans:wght@400;700&family=Merriweather:wght@400;700&family=Roboto+Condensed:wght@400;700&family=Ubuntu:wght@400;700&family=Noto+Sans:wght@400;700&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;700&family=Lora:wght@400;700&family=PT+Serif:wght@400;700&family=Muli:wght@400;700&family=Nunito:wght@400;700&family=Fira+Sans:wght@400;700&family=Work+Sans:wght@400;700&family=Titillium+Web:wght@400;700&family=Dosis:wght@400;700&family=Hind:wght@400;700&family=Arimo:wght@400;700&family=Cabin:wght@400;700&family=Exo+2:wght@400;700&family=Inconsolata:wght@400;700&family=Karla:wght@400;700&family=Heebo:wght@400;700&family=Quicksand:wght@400;700&family=Rubik:wght@400;700&family=Josefin+Sans:wght@400;700&family=Barlow:wght@400;700&family=Anton:wght@400;700&family=Pacifico:wght@400;700&family=Abel:wght@400;700&family=Yanone+Kaffeesatz:wght@400;700&family=Indie+Flower:wght@400;700&family=Oxygen:wght@400;700&family=Nanum+Gothic:wght@400;700&family=Rajdhani:wght@400;700&family=Kanit:wght@400;700&family=Questrial:wght@400;700&family=Balsamiq+Sans:wght@400;700&family=Manrope:wght@400;700&family=Varela+Round:wght@400;700&family=Crimson+Text:wght@400;700&family=Asap:wght@400;700&family=Signika:wght@400;700&family=Cardo:wght@400;700&family=Kreon:wght@400;700&family=Space+Mono:wght@400;700&family=Fjalla+One:wght@400;700&family=Concert+One:wght@400;700&family=Francois+One:wght@400;700&family=Righteous:wght@400;700&family=Cinzel:wght@400;700&display=swap");

/* General */

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color: var(--black);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  /* color: rgba(0, 0, 0, 0.86); */
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--black);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}
