.pageContainer {
  &,
  & > div {
    display: grid;
    gap: 2.3rem;
    align-items: start;
  }
  grid-template-columns: 1fr 0.94fr;

  & > div {
    flex-direction: column;
  }
}
.cardFromWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .formSplitGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.errorMessage {
  color: #cc0000;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.productsList {
  margin-top: 2rem;
}

.productItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .productPrice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: 500;
    color: #000000;
  }
}

.productNameContainer {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }
  
  .productName {
    font-size: 1.4rem;
    font-weight: 500;
    // ellipsis with 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 250px;
  }
}

.deleteModalText {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
}