.addWebhookContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  .left {
    .nav {
      display: flex;
      align-items: center;
      padding: 1.8rem 1.9rem;
      gap: 1rem;
      .closeContainer {
        cursor: pointer;
        padding: 0.2rem;
      }
      .divider {
        height: 20px;
        width: 1px;
        background-color: #c8c8c8;
        flex-shrink: 0;
      }
      .title {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 500;
        color: #1a1e26;
      }

      .activeItem {
        margin-left: auto;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #cfd7df;
        color: #1a1e26;
        height: 30.75px;
        padding: 0 0.825rem;
        padding-right: 1.025rem;
        > * {
          transform: scale(0.8);
          margin-right: -4px;
          label {
            background: #b5b5b5;
          }
        }
        span {
          font-size: 15px;
          font-weight: 500;
        }
        &.active {
          border: 1px solid #56b2a3;
          background: #56b2a313;
          > * {
            label {
              background: #56b2a3;
            }
          }
        }
      }
    }

    .formContainer {
      padding: 4rem 2rem 3rem;
      max-width: 620px;
      margin: 0 auto;
    }

    .pageTitle {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .pageDescription {
      font-size: 15px;
      line-height: 1.4;
      margin-bottom: 1rem;
      a {
        color: #3447ef;
      }
    }

    .webhookFormGrid {
      margin-top: 3rem;
      display: grid;
      gap: 2rem;
    }

    .buttonsContainer {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
    }
  }

  .right {
    background-color: #f9f9f9;
    border-left: 1px solid #ececec;
  }
}

.deleteButton {
  margin-left: auto;
}

.secret {
  margin: 2rem 0;
}

.secretReveal {
  cursor: pointer;
  color: #3447ef;
  font-size: 14px;
  font-weight: 500;
}

.secretValue {
  font-size: 14px;
  font-weight: 500;
  color: #1a1e26;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.right {
  overflow: auto;
  .codeContainer {
    padding: 2rem;
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: relative;
  }

  .lang {
    position: absolute;
    top: 1rem;
    right: 2rem;

    select {
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 0.5rem;
      background-color: transparent;
      font-size: 14px;
    }
  }

  .container {
    * {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace !important;
    }
  }

  .row {
    display: table-row;
  }

  .lineNumber {
    display: table-cell;
    padding-right: 1rem;
    font-size: 14px;
    color: #9ca3af;
    text-align: right;
    user-select: none;
  }

  .lineContent {
    display: table-cell;
    font-size: 14px; // Specific to the code content
    padding: 3px 0;
    padding-left: 2rem;
    font-family: "Menlo", "Monaco", "Consolas", "Courier New", monospace;
    letter-spacing: 0.04em;
  }
}

.copy {
  z-index: 1000;
  position: absolute;
  bottom: 2rem;
  right: 10rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #1a1e26;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ececec;
  &:hover {
    background-color: #f9f9f9;
  }
}

.optionContainer {
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  strong {
    font-weight: 500;
    font-size: 1.3rem;
    color: #1a1e26;
  }
  div {
    font-size: 1.2rem;
    font-weight: 400;
    color: #1a1e26;
  }
}

.testDivider {
  margin-left: 1rem;
}