.emptyState {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
}
