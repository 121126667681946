.onboardingPage {
  height: 100vh;
  overflow: overlay;
  padding-bottom: 160px;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .stepIndicator {
    height: 8px;
    width: 100px;
    background: #e4e9ef;
    border-radius: 8px;
    margin-bottom: 24px;
    .indicator {
      height: 100%;
      background: #3447ef;
      border-radius: 8px;
    }
  }
  .pageContent {
    padding-top: 20px;
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    position: relative;
    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      border-radius: 4px;
      cursor: pointer;
      position: absolute;
      left: -46px;
      color: #afb7c4;
      &:hover {
        background-color: #f5f6f7;
        color: #737e90;
      }
      &:active {
        background-color: #e4e9ef;
        color: #535c6a;
      }
    }
  }
}

.buttonContainer {
  margin-top: 30px;
  button {
    font-size: 14px;
    padding: 1rem 1.4rem;
  }
}

.step {
  .divider {
    border: none;
    border-bottom: 1px solid rgba(#1a1e26, 0.2);
    margin: 16px 0;
  }
  .title {
    color: #1a1e26;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  .subTitle,
  .text {
    color: #51565d;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 24px;
  }
  .text {
    margin-bottom: 0;
    a {
      color: #3447ef;
      text-decoration: underline;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }
}

.agree {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .agreeText {
    font-size: 14px;
    color: #51565d;
    a {
      color: #3447ef;
      text-decoration: none;
    }
  }
}

.radioInputContainer {
  display: flex;
  align-items: center;
  .radioInputLabel {
    font-size: 14px;
    color: #51565d;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.import {
  max-width: 520px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 12px 36px 16px 18px;
  border-radius: 4px;
  background-color: #f5f6f7;
  border: 1px solid #d6dff3;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: #51565d;
    &:hover {
      color: #1a1e26;
    }
  }
  .text {
    display: flex;
    gap: 10px;
    span {
      font-size: 14px;
      line-height: 1.4;
      color: #51565d;
    }
    img {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      object-fit: cover;
    }
  }
  .btn {
    pointer-events: none;
  }
  button {
    margin-top: 10px;
    height: 30.75px;
    padding: 0.6375rem 1.125rem;
  }
}

.usernameHolder {
  position: relative;
  .codeInput {
    position: absolute;
    max-width: 160px;
    left: -180px;
  }
  .suffix {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24px;
    height: 34px;
    right: 1px;
    border-radius: 0 3px 3px 0;
    font-size: 14px;
    color: #51565d;
    border-left: 1px solid #e5e5e5;
    padding: 0 12px 0 12px;
    background-color: #f6f8fa;
  }

  .loading,
  .succeeded,
  .failed {
    position: absolute;
    right: 1px;
    bottom: -16px;

    font-size: 10px;
    color: #51565d;
  }

  .succeeded {
    font-weight: 600;
    color: #2ecc71;
  }

  .failed {
    font-weight: 600;
    color: #e74c3c;
  }
}
