.pageContainer {
  margin-top: -34px;

  left: 0;
  right: 0;

  position: absolute;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  .formsContainer {
    padding: 18px 34px 40px;
    overflow: overlay;
    flex-grow: 1;
    flex-basis: auto;

    .form {
      width: 100%;
      max-width: 790px;
      margin: auto;
    }
    .stepIndicator {
      border-radius: 1px;
      height: 3px;
      background: #d9d9d9;
      .stepIndicatorItem {
        border-radius: 1px;
        background: #3447ef;
        height: 3px;
      }
    }
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-bottom: 18px;
      flex-wrap: nowrap;
      h1 {
        flex: 1;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        color: #1a1e26;
        font-size: 2.125rem;
        font-weight: 600;
        margin-right: 1rem;
        // two line ellipsis
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines you want to display */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      .actions {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .divider {
          border: none;
          border-left: 1px solid rgba(#1a1e26, 0.2);
          margin: 0 8px;
          height: 24px;
        }
        button {
          height: 30.75px;
          padding: 0.6375rem 1.125rem;
        }
        .publishedItem {
          display: flex;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #cfd7df;
          color: #1a1e26;
          height: 30.75px;
          padding: 0 0.825rem;
          padding-right: 1.025rem;
          > * {
            transform: scale(0.8);
            margin-right: -4px;
            label {
              background: #b5b5b5;
            }
          }
          span {
            font-size: 15px;
            font-weight: 500;
          }
          &.published {
            border: 1px solid #56b2a3;
            background: #56b2a313;
            > * {
              label {
                background: #56b2a3;
              }
            }
          }
        }
      }
    }
  }
  .formContent {
    padding-top: 30px;
    .stepCounter {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #3447ef;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }

  .previewContainer {
    background: #edeff6;
    flex: 0.1;
    min-width: 500px;

    .previewNavbar {
      padding: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 18px;
      min-width: 500px;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        color: #1a1e26;
        font-size: 2.125rem;
        font-weight: 600;
      }
      .actions {
        display: flex;
        gap: 0.8rem;
      }
      button {
        height: 30.75px;
        padding: 0.6375rem 1.125rem;
      }
    }

    .pagesPreview {
      width: 100%;
      padding: 18px;
      padding-top: 0;
      margin: auto;
      width: 500px;
      .pagePreview {
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #d2d2d2;
        box-shadow: 0px 3px 10px -3px rgb(218 220 231);
        pointer-events: none;
      }
      > * {
        transform-origin: top left;
        margin: auto;
        transform: scale(0.47);
        width: 244%;
        max-width: 980px;
        button {
          pointer-events: none;
        }
      }
    }
  }
}

.step {
  h1 {
    color: #1a1e26;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .desc {
    color: #797b7e;
    font-size: 13px;
    font-family: Inter;
    line-height: 19px;
    margin-bottom: 30px;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}

.productsList {
  margin-top: 2rem;
}

.productItem {
  display: flex;
  align-items: center;
  gap: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 1.4rem;
    padding-bottom: 1.4rem;
  }

  .productPrice {
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #1a1e26;
  }

  & > *:first-child {
    flex-shrink: 0;
  }
}

.productNameContainer {
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }

  .productName {
    font-size: 13px;
    color: #1a1e26;
    font-weight: 500;
    // ellipsis with 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 250px;
  }
}

.selectAll {
  display: flex;
  gap: 12px;
  label {
    font-size: 13px;
    color: #1a1e26;
    font-weight: 500;
  }
}

.deleteModalText {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000000;
}

.notificationContainer {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  border-radius: 6px;
  background: #1f1d2a;
  color: #f2f1fa;
  display: flex;
  gap: 6px;
  padding: 14px 14px 14px 16px;
  width: 360px;
  opacity: 0;
  transform: translateX(50px);
  transition: all 0.3s ease;
  display: none;
  &.visible {
    display: flex;
    opacity: 1;
    transform: translateX(0);
  }
  img {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    border-radius: 38px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .notifText {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
    .subTitle {
      font-size: 12px;
      line-height: 15px;
      color: #c2c1ca;
    }
    .buttonsContainer {
      margin-top: 3px;
      button {
        height: 27px;
        padding: 0.3375rem 1.125rem;
        color: #f2f1fa;
      }
    }
  }
}

@media (max-width: 1390px) {
  .pageContainer {
    .formsContainer {
      .form {
        max-width: 100%;
      }
    }
    .previewContainer {
      min-width: 450px;
      .previewNavbar {
        min-width: 450px;
      }
      .pagesPreview {
        width: 450px;
        > * {
          transform: scale(0.425);
          width: 244%;
          max-width: 980px;
        }
      }
    }
  }
}
