.creatorMessageContainer {
  .added-button {
    background-color: #3447ef;
    color: #fff;
    border-radius: 4px;
    padding: 10px 14px;
    margin: 0 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;

    &:hover {
      background-color: #5469d4;
    }
  }

  a:not(.added-button) {
    color: #007bff;
    text-decoration: underline;
  }

  > * + * {
    margin-top: 0.75em;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin: 0.8em 0;
    padding: 0 0 0 2em;
    ::marker {
      font-size: 1.8em;
    }

    li {
      > ul,
      > ol {
        margin: 0;
      }

      margin-bottom: 0.9em !important;
      p {
        display: inline;
        margin: 0;
      }
    }
  }

  img {
    width: 90%;
    margin: 0 auto !important;
    border-radius: 4px;
  }
}
