.datePicker {
  position: relative;
  z-index: 9999;
  input {
    height: 30px;
    width: 180px;
    font-size: 1.2rem;
    padding: 0 12px;

    background-color: #f6f9fc;
    border: 1px solid #cfd7df;
    border-radius: 4px;
    color: #32325d;

    &:focus {
      outline: none;
      border-color: #3447ef;
      box-shadow: 0 0 0 1px #3447ef;
    }

    &::placeholder {
      color: #8898aa;
    }
  }
}

.custom-tooltip {
  background-color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  color: #fff;
  font-size: 14px;
}

.label {
  font-weight: bold;
  margin-bottom: 6px;
}

.tooltip-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tooltip-list li {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.data-marker {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.name {
  margin-right: 4px;
}

.value {
  font-weight: bold;
}

.recharts-layer.recharts-cartesian-axis-tick {
  display: none;
}

.recharts-layer.recharts-cartesian-axis-tick:nth-child(1),
.recharts-layer.recharts-cartesian-axis-tick:nth-last-child(1) {
  display: block;
}

.rdrDefinedRangesWrapper {
  width: 120px;
}
