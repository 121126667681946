/* OrSeparator.module.css */
.orSeparator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a2a2a2;
  margin: 1rem 0;
}

.line {
  flex-grow: 1;
  border-bottom: 1px solid #c5c5c5;
  margin: 0 6px;
}
