.card {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d2d2d2;;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
}

.title {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 1.8rem;
  &.centerTitle {
    text-align: center;
  }
}
